@import "../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@import "./colors.less";

@theme: variable;

// -------- Colors -----------
// >>> Primary
@primary-color: var(--screeb-color-primary);
@primary-color-hover: var(--screeb-color-primary-active);
@primary-color-active: var(--screeb-color-primary-active);
@primary-color-outline: var(--screeb-color-primary-outline);

@processing-color: var(--screeb-color-blue-500);

// >>> Info
@info-color: var(--screeb-color-primary);
@info-color-deprecated-bg: var(--screeb-color-primary-outline);
@info-color-deprecated-border: var(--screeb-color-primary-active);

// >>> Success
@success-color: var(--screeb-color-success);
@success-color-hover: var(--screeb-color-success-active);
@success-color-active: var(--screeb-color-success-active);
@success-color-outline: var(--screeb-color-success-outline);

// >>> Warning
@warning-color: var(--screeb-color-warning);
@warning-color-hover: var(--screeb-color-active);
@warning-color-active: var(--screeb-color-active);
@warning-color-outline: var(--screeb-color-outline);

// >>> Error
@error-color: var(--screeb-color-error);
@error-color-hover: var(--screeb-color-error-active);
@error-color-active: var(--screeb-color-error-active);
@error-color-outline: var(--screeb-color-error-outline);

@highlight-color:var (--screeb-color-error);
@normal-color: var(--screeb-color-body-text);
@white: var(--screeb-color-white);
@black: var(--screeb-color-black);

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
@primary-1: var(--screeb-color-purple-alpha);
@primary-2: var(--screeb-color-purple-200);
@primary-3: var(--screeb-color-purple-300); // unused
@primary-4: var(--screeb-color-purple-400); // unused
@primary-5: var(--screeb-color-purple-500);
@primary-6: @primary-color; // color used to control the text color of active buttons, don't use, use @primary-color
@primary-7: var(--screeb-color-purple-700);
@primary-8: var(--screeb-color-purple-900); // unused
@primary-9: var(--screeb-color-purple-950); // unused
@primary-10: var(--screeb-color-purple-950); // unused

// Base Scaffolding Variables
// ---

// Background color for `<body>`
@body-background: var(--screeb-color-body-background);
// Base background color for most components
@component-background: var(--screeb-color-background);
@font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
@code-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
  monospace;
@text-color: var(--screeb-color-body-text);
@text-color-secondary: var(--screeb-color-body-text-secondary);
@text-color-inverse: var(--screeb-color-body-text-invert);
@icon-color: inherit;
@icon-color-hover: var(--screeb-color-body-text-secondary);
@heading-color: var(--screeb-color-body-text-secondary);
@text-color-dark: var(--screeb-color-body-text-invert);
@text-color-secondary-dark: var(--screeb-color-body-text-invert);

// The background colors for active and hover states for things like
// list items or table cells.
@item-active-bg: @primary-1;
@item-hover-bg: var(--screeb-color-background-secondary);

// LINK
@link-color: @primary-color;
@link-hover-color: @primary-color-hover;
@link-active-color: @primary-color-hover;

// Border color
@border-color-base: var(
  --screeb-color-border
); // base border outline a component
@border-color-split: var(
  --screeb-color-border-secondary
); // split border inside a component
@border-color-inverse: var(--screeb-color-background);

@background-color-light: var(
  --screeb-color-background-secondary
); // background of header and selected item
@background-color-base: var(
  --screeb-color-background-secondary
); // Default grey background color

// Disabled states
@disabled-color: var(--screeb-color-disabled);
@disabled-bg: var(--screeb-color-disabled-background);
@disabled-active-bg: var(--screeb-color-disabled-active);
@disabled-color-dark: var(--screeb-color-disabled-invert);

// Buttons
@btn-border-radius-base: 8px; // custom @samber 2020-11-17
@btn-disable-border: var(--screeb-color-border-secondary);

// Descriptions
@descriptions-bg: var(--screeb-color-background-secondary);

// Radio
@radio-dot-disabled-color: var(--screeb-color-disabled);

// Layout
@layout-body-background: var(--screeb-color-body-background);
@layout-header-background: var(--screeb-color-background);
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
// Layout light theme
@layout-sider-background-light: var(--screeb-color-background);
@layout-trigger-background-light: var(--screeb-color-background);

// Input
// ---
@input-number-handler-active-bg: var(--screeb-color-background-secondary);
@input-icon-hover-color: var(--screeb-color-body-text-secondary);

// Select
// ---
@select-multiple-item-disabled-color: var(--screeb-color-disabled);

// Tooltip
// ---
@tooltip-color: #fff;
@tooltip-bg: rgba(0, 0, 0, 0.75);

// Modal
// --
@modal-mask-bg: rgba(0, 0, 0, 0.45);

// Progress
// --
@progress-steps-item-bg: var(--screeb-color-background-secondary);

// dark theme
@menu-dark-arrow-color: var(--screeb-color-body-invert);
@menu-dark-inline-submenu-bg: var(--screeb-color-disabled-active);
@menu-dark-highlight-color: var(--screeb-color-body-invert);

// Table
// --
@table-body-sort-bg: var(--screeb-color-background-secondary);
@table-selected-row-hover-bg: var(--screeb-color-background-secondary);
@table-expanded-row-bg: var(--screeb-color-background-secondary);
@table-header-cell-split-color: rgba(0, 0, 0, 0.06);
// Sorter
// Legacy: `table-header-sort-active-bg` is used for hover not real active
@table-header-sort-active-bg: rgba(0, 0, 0, 0.04);
@table-fixed-header-sort-active-bg: hsv(0, 0, 96%);

// Filter
@table-header-filter-active-bg: rgba(0, 0, 0, 0.04);
// Sticky
@table-sticky-scroll-bar-bg: var(--screeb-color-background-secondary);

// Calendar
// ---
@calendar-column-active-bg: var(--screeb-color-background-secondary);

// Card
// ---
@card-skeleton-bg: var(--screeb-color-background-secondary);

// Comment
// ---
@comment-author-time-color: var(--screeb-color-body-text);
@comment-action-hover-color: var(--screeb-color-body-text-secondary);

// Tabs
// ---

// BackTop
// ---
@back-top-color: var(--screeb-color-body-text-invert);

// Avatar
// ---
@avatar-bg: var(--screeb-color-background);
@avatar-color: var(--screeb-color-body-text-invert);
@avatar-group-border-color: var(--screeb-color-border);

// PageHeader
// ---
@page-header-back-color: var(--screeb-color-body-text);

// Slider
// ---
@slider-rail-background-color-hover: var(--screeb-color-background-secondary);
@slider-handle-color-focus-shadow: var(--screeb-color-primary-outline);
@slider-dot-border-color-active: var(--screeb-color-primary-active);

// Tree
// ---
@tree-directory-selected-color: var(--screeb-color-body-text-invert);

// Skeleton
// ---
@skeleton-color: var(--screeb-color-input-background);
@skeleton-to-color: var(--screeb-color-disabled);

// Alert
// ---
@alert-success-border-color: @success-color;
@alert-success-bg-color: var(--screeb-color-green-200);
@alert-info-border-color: @info-color;
@alert-info-bg-color: var(--screeb-color-purple-200);
@alert-warning-border-color: @warning-color;
@alert-warning-bg-color: var(--screeb-color-yellow-200);
@alert-error-border-color: @error-color;
@alert-error-bg-color: var(--screeb-color-red-200);

// Steps
// ---
@steps-nav-arrow-color: var(--screeb-color-body-text-secondary);

// Image
// ---
@image-bg: var(--screeb-color-background-secondary);
@image-color: var(--screeb-color-body-text-invert);
@image-preview-operation-disabled-color: var(--screeb-color-disabled);

// Segmented
// ---
@segmented-bg: var(--screeb-color-background-secondary);
@segmented-hover-bg: var(--screeb-color-background);
@segmented-selected-bg: var(--screeb-color-background);
@segmented-label-color: var(--screeb-color-disabled-active);
@segmented-label-hover-color: var(--screeb-color-body-text-secondary);
