// color palettes
@blue-base: var(--screeb-color-blue-500);
@blue-1: var(--screeb-color-blue-alpha);
@blue-2: var(--screeb-color-blue-200);
@blue-3: var(--screeb-color-blue-200);
@blue-4: var(--screeb-color-blue-200);
@blue-5: var(--screeb-color-blue-500);
@blue-6: @blue-base;
@blue-7: var(--screeb-color-blue-500);
@blue-8: var(--screeb-color-blue-700);
@blue-9: var(--screeb-color-blue-700);
@blue-10: var(--screeb-color-blue-700);

@purple-base: var(--screeb-color-purple-500);
@purple-1: var(--screeb-color-purple-alpha);
@purple-2: var(--screeb-color-purple-200);
@purple-3: var(--screeb-color-purple-300);
@purple-4: var(--screeb-color-purple-300);
@purple-5: var(--screeb-color-purple-500);
@purple-6: @purple-base;
@purple-7: var(--screeb-color-purple-500);
@purple-8: var(--screeb-color-purple-700);
@purple-9: var(--screeb-color-purple-900);
@purple-10: var(--screeb-color-purple-950);

@green-base: var(--screeb-color-green-500);
@green-1: var(--screeb-color-green-alpha);
@green-2: var(--screeb-color-green-200);
@green-3: var(--screeb-color-green-200);
@green-4: var(--screeb-color-green-400);
@green-5: var(--screeb-color-green-500);
@green-6: @green-base;
@green-7: var(--screeb-color-green-500);
@green-8: var(--screeb-color-green-600);
@green-9: var(--screeb-color-green-700);
@green-10: var(--screeb-color-green-700);

@red-base: var(--screeb-color-red-500);
@red-1: var(--screeb-color-red-alpha);
@red-2: var(--screeb-color-red-200);
@red-3: var(--screeb-color-red-200);
@red-4: var(--screeb-color-red-200);
@red-5: var(--screeb-color-red-500);
@red-6: @red-base;
@red-7: var(--screeb-color-red-500);
@red-8: var(--screeb-color-red-700);
@red-9: var(--screeb-color-red-700);
@red-10: var(--screeb-color-red-700);

@yellow-base: var(--screeb-color-yellow-500);
@yellow-1: var(--screeb-color-yellow-alpha);
@yellow-2: var(--screeb-color-yellow-200);
@yellow-3: var(--screeb-color-yellow-200);
@yellow-4: var(--screeb-color-yellow-200);
@yellow-5: var(--screeb-color-yellow-500);
@yellow-6: @yellow-base;
@yellow-7: var(--screeb-color-yellow-500);
@yellow-8: var(--screeb-color-yellow-600);
@yellow-9: var(--screeb-color-yellow-700);
@yellow-10: var(--screeb-color-yellow-700);
