@import "https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,900&display=swap";
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,900&display=swap"; // fallback

// @import '~highlight.js/styles/default.css';
@import "../node_modules/highlight.js/styles/github.css";

@import "./themes/variables.scss";
@import "./themes/helpers.scss";

#screeb-tag-storage {
  display: none !important;
}

img.screeb-emoji {
  height: 24px;
  width: 24px;
  margin: 0 0.05em 0 10px;
  vertical-align: -10px;
}

.ant-popover-inner {
  box-shadow: 0px 2px 16px var(--screeb-color-grey-alpha);
  border-radius: 16px;
}

.ant-tooltip-arrow-content,
.ant-popover-arrow-content {
  box-shadow: none !important;
}

.ant-popover-arrow {
  display: none;
}

.bottom-menu-overlay--secondary .ant-popover-arrow,
.bottom-menu-overlay--secondary .ant-tooltip-arrow-content {
  display: block;
}

.ant-select-selector {
  background: var(--screeb-color-background) !important;

  border: 1px solid var(--screeb-color-border-secondary);
  box-sizing: border-box !important;
  border-radius: 8px !important;
}

.ant-input,
.ant-input-number,
.ant-input-affix-wrapper {
  border-radius: 8px !important;
}

.ant-tag {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  background: var(--screeb-color-background-secondary);
  border-color: var(--screeb-color-border);
  color: var(--screeb-color-body-text);

  padding: 3px 8px;

  .tag {
    color: var(--screeb-color-white);
  }

  i {
    color: var(--screeb-color-body-text) !important;
  }
}

nz-select[nzmode="tags"] .ant-select-selection-item {
  background: var(--screeb-color-background-secondary) !important;
  border-color: var(--screeb-color-border) !important;
  color: var(--screeb-color-body-text) !important;
}

.ant-tag-checkable-checked {
  background: var(--screeb-color-purple-500);
  color: var(--screeb-color-white);
  border-radius: 5px;
}

.ant-menu-inline.screeb-menu {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      color: var(--screeb-color-body-text);
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;

      display: flex !important;
      align-items: center !important;

      padding-left: 8px !important;
    }

    &.ant-menu-submenu-open {
      background: var(--screeb-color-background-secondary) !important;
      border-radius: 8px;

      margin-bottom: 6px;

      .ant-menu-submenu-title {
        font-weight: bold;
        color: var(--screeb-color-body-text);
      }
    }

    .ant-menu-sub {
      background: transparent !important;

      ul {
        padding: 0 8px 8px 8px;
      }
    }

    .ant-menu-item {
      background: var(--screeb-color-background);
      color: var(--screeb-color-body-text-secondary);

      font-family: Rubik !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 150% !important;

      height: auto;

      min-height: 30px;
      border-radius: 8px;

      display: flex !important;
      align-items: center !important;

      padding-left: 8px !important;

      &.ant-menu-item-selected {
        background: var(--screeb-color-background);

        a {
          font-family: Rubik !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 14px !important;
          line-height: 150% !important;
          color: var(--screeb-color-purple-500) !important;
        }

        &::after {
          width: 4px;
          height: 16px;
          right: 8px;
          top: 7px;

          background: var(--screeb-color-purple-500);
          border-radius: 3px;

          animation: none !important;
          transition: none !important;
        }
      }

      &::after {
        animation: none !important;
        transition: none !important;
      }

      a {
        padding: 0 !important;
      }

      + .ant-menu-item {
        margin-top: 10px;
      }
    }
  }
}

.ant-timeline {
  li:last-child {
    padding-bottom: 0;
  }

  margin-bottom: -20px;
}

.ant-tag {
  border-radius: 4px;
  border: none;
}

::ng-deep .nz-breadcrumb-item {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--screeb-color-body-text-secondary);
}

::ng-deep .ant-breadcrumb > nz-breadcrumb-item:last-child {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--screeb-color-grey-700);
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;

  screeb-icon {
    margin-right: 8px;
  }

  .anticon-loading {
    margin-right: 8px;
  }

  &.ant-btn-loading screeb-icon {
    display: none;
  }
}

.ant-btn-circle {
  justify-content: center;
}

.ant-btn-secondary {
  border-color: var(--screeb-color-green-700);
  background: var(--screeb-color-green-700);
  color: var(--screeb-color-white);
}

.ant-modal-content {
  border-radius: 12px;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:active,
.ant-btn-secondary:focus {
  color: var(--screeb-color-white);
  background: var(--screeb-color-green-600);
  border-color: var(--screeb-color-green-600);
}

.ant-btn-secondary:disabled {
  color: var(--screeb-color-grey-alpha);
  background: var(--screeb-color-grey-200);
  border-color: var(--screeb-color-border-secondary);
}

.ant-btn-secondary.ant-btn-background-ghost {
  color: var(--screeb-color-green-700);
  background: var(--screeb-color-background);
  border-color: var(--screeb-color-green-700);
}

.ant-btn-secondary.ant-btn-background-ghost:hover,
.ant-btn-secondary.ant-btn-background-ghost:active,
.ant-btn-secondary.ant-btn-background-ghost:focus {
  color: var(--screeb-color-green-700);
  background: var(--screeb-color-background);
  border-color: var(--screeb-color-green-500);
}

// fuck this
.bottom-menu-overlay {
  .ant-popover-inner-content {
    padding: 16px;
  }

  nz-progress {
    margin-top: -0.25rem;
    display: block;
  }

  li a,
  a:active,
  a:visited {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: var(--screeb-color-body-text);
  }

  hr {
    border-style: dashed;
    border-color: var(--screeb-color-border);
  }

  screeb-icon {
    color: var(--screeb-color-body-text-secondary);
    margin-right: 8px;
    opacity: 0.33;
    transition: color 0.15s ease;
  }

  span + screeb-icon {
    width: 12px !important;
    height: 12px !important;
    margin-left: 0.25rem;
    color: var(--screeb-color-body-text);
    display: inline-flex;
    align-items: center;
  }

  li + li {
    margin-top: 8px;
  }

  li:hover a {
    color: var(--screeb-color-primary);
  }

  li:hover a screeb-icon {
    color: var(--screeb-color-primary-active);
  }
}
