:root,
.is-light {
  // Initial variables
  --screeb-color-white: #ffffff;
  --screeb-color-black: #000000;

  --screeb-color-grey-700: #303140;
  --screeb-color-grey-600: #4a4b5f;
  --screeb-color-grey-500: #727387;
  --screeb-color-grey-300: #ceced7;
  --screeb-color-grey-200: #f3f4f9;
  --screeb-color-grey-alpha: rgba(48, 49, 64, 0.15);

  --screeb-color-purple-950: #271041;
  --screeb-color-purple-900: #331b4e;
  --screeb-color-purple-700: #502c9e;
  --screeb-color-purple-500: #5e21f1;
  --screeb-color-purple-400: #7a4dd1;
  --screeb-color-purple-300: #9f87a6;
  --screeb-color-purple-250: #b99cc1;
  --screeb-color-purple-200: #e2d6ff;
  --screeb-color-purple-alpha: rgba(94, 33, 241, 0.15);

  --screeb-color-green-700: #086554;
  --screeb-color-green-600: #15a179;
  --screeb-color-green-500: #1ed5a4;
  --screeb-color-green-400: #2be1b0;
  --screeb-color-green-200: #abf3e0;
  --screeb-color-green-alpha: rgba(30, 213, 164, 0.15);

  --screeb-color-blue-700: #0054b6;
  --screeb-color-blue-500: #0094ff;
  --screeb-color-blue-200: #d0e4fd;
  --screeb-color-blue-alpha: rgba(0, 148, 255, 0.15);

  --screeb-color-yellow-700: #c88502;
  --screeb-color-yellow-600: #faad14;
  --screeb-color-yellow-500: #ffb546;
  --screeb-color-yellow-300: #fef6e8;
  --screeb-color-yellow-200: #fff0da;
  --screeb-color-yellow-alpha: rgba(255, 181, 70, 0.2);

  --screeb-color-red-700: #68052f;
  --screeb-color-red-500: #f11672;
  --screeb-color-red-400: #f53b8c;
  --screeb-color-red-200: #ffd6dd;
  --screeb-color-red-alpha: rgba(241, 22, 114, 0.15);

  // Derived variables
  --screeb-color-body-background: var(--screeb-color-white);
  --screeb-color-body-text: var(--screeb-color-grey-700);
  --screeb-color-body-text-invert: var(--screeb-color-white);
  --screeb-color-body-text-secondary: var(--screeb-color-grey-500);
  --screeb-color-background: var(--screeb-color-white);
  --screeb-color-background-secondary: var(--screeb-color-grey-200);
  --screeb-color-border: var(--screeb-color-grey-200);
  --screeb-color-border-secondary: var(--screeb-color-grey-300);
  --screeb-color-input-background: var(--screeb-color-grey-200);
  --screeb-color-primary: var(--screeb-color-purple-500);
  --screeb-color-primary-active: var(--screeb-color-purple-700);
  --screeb-color-primary-outline: var(--screeb-color-purple-200);
  --screeb-color-success: var(--screeb-color-green-500);
  --screeb-color-success-active: var(--screeb-color-green-700);
  --screeb-color-success-outline: var(--screeb-color-green-200);
  --screeb-color-info: var(--screeb-color-blue-500);
  --screeb-color-info-active: var(--screeb-color-blue-700);
  --screeb-color-info-outline: var(--screeb-color-blue-200);
  --screeb-color-warning: var(--screeb-color-yellow-500);
  --screeb-color-warning-active: var(--screeb-color-yellow-700);
  --screeb-color-warning-outline: var(--screeb-color-yellow-200);
  --screeb-color-error: var(--screeb-color-red-500);
  --screeb-color-error-active: var(--screeb-color-red-700);
  --screeb-color-error-outline: var(--screeb-color-red-200);
  --screeb-color-disabled: var(--screeb-color-grey-500);
  --screeb-color-disabled-active: var(--screeb-color-grey-700);
  --screeb-color-disabled-background: var(--screeb-color-grey-300);
  --screeb-color-disabled-invert: var(--screeb-color-grey-200);
  --screeb-color-box-shadow: #3031400d;
}

// TODO: not finished
:root[data-theme="dark"],
.is-dark {
  --screeb-color-body-background: var(--screeb-color-grey-700);
  --screeb-color-body-text: var(--screeb-color-grey-200);
  --screeb-color-body-text-invert: var(--screeb-color-grey-700);
  --screeb-color-body-text-secondary: var(--screeb-color-grey-300);
  --screeb-color-background: var(--screeb-color-grey-700);
  --screeb-color-background-secondary: var(--screeb-color-grey-600);
  --screeb-color-border: var(--screeb-color-grey-600);
  --screeb-color-border-secondary: var(--screeb-color-grey-600);
  --screeb-color-input-background: var(--screeb-color-grey-500);
  --screeb-color-primary: var(--screeb-color-purple-400);
  --screeb-color-primary-active: var(--screeb-color-purple-300);
  --screeb-color-primary-outline: var(--screeb-color-purple-900);
  --screeb-color-success: var(--screeb-color-green-500);
  --screeb-color-success-active: var(--screeb-color-green-300);
  --screeb-color-success-outline: var(--screeb-color-green-700);
  --screeb-color-info: var(--screeb-color-blue-500);
  --screeb-color-info-active: var(--screeb-color-blue-200);
  --screeb-color-info-outline: var(--screeb-color-blue-alpha);
  --screeb-color-error: var(--screeb-color-red-500);
  --screeb-color-error-active: var(--screeb-color-red-400);
  --screeb-color-error-outline: var(--screeb-color-red-700);
  --screeb-color-warning: var(--screeb-color-yellow-500);
  --screeb-color-warning-active: var(--screeb-color-yellow-200);
  --screeb-color-warning-outline: var(--screeb-color-yellow-700);
}
